$body-color: white;
$body-bg: #181818;
$font-family-base: 'Montserrat', 'Tajawal', sans-serif;
$primary: #e53946;
$secondary: #eec380;

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
);

html {
  background: #181818;
}

#root {
  position: relative;
  z-index: 1;
}


@mixin gradientBackgroundImage($base-color) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='400' width='400'%3E%3Cdefs%3E%3CradialGradient id='RadialGradient1' cy='-100%25' cx='-100%25' r='200%25'%3E%3Cstop offset='0%25' stop-color='#{encodecolor(lighten(desaturate($base-color, 20%), 20%))}'/%3E%3Cstop offset='100%25' stop-color='#{encodecolor(darken($base-color, 20%))}'/%3E%3Canimate id='cxp' attributeName='cx' dur='30s' from='-100%25' to='200%25' begin='0s;cyn.end - 1s' fill='freeze' repeatCount='1' calcMode='spline' keySplines='0.65, 0, 0.35, 1; 0.65, 0, 0.35, 1'/%3E%3Canimate id='cyp' attributeName='cy' dur='30s' from='-100%25' to='200%25' begin='cxp.end - 2.5s' fill='freeze' repeatCount='1' calcMode='spline' keySplines='0.65, 0, 0.35, 1; 0.65, 0, 0.35, 1'/%3E%3Canimate id='cxn' attributeName='cx' dur='30s' from='200%25' to='-100%25' begin='cyp.end - 1s' fill='freeze' repeatCount='1' calcMode='spline' keySplines='0.65, 0, 0.35, 1; 0.65, 0, 0.35, 1'/%3E%3Canimate id='cyn' attributeName='cy' dur='30s' from='200%25' to='-100%25' begin='cxn.end - 1s' fill='freeze' repeatCount='1' calcMode='spline' keySplines='0.65, 0, 0.35, 1; 0.65, 0, 0.35, 1'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect width='100%25' height='100%25' x='0' y='0' fill='url(%23RadialGradient1)'/%3E%3C/svg%3E");
}

@function encodecolor($string) {
  @if type-of($string)=='color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }

  $string: '%23'+$string;
  @return $string;
}

@import "../../node_modules/bootstrap-rtlized/scss/bootstrap.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";


.h-100vh {
  height: 100vh;
}

.os-android, .os-ios {
  height: calc(100vh - 56px) !important;
  .h-100vh {
    height: calc(100vh - 56px) !important;

  }
}

body {
  background-position: center;
  background-size: cover;
  //@include gradientBackgroundImage(lighten(#181818, 20%));
  // background: radial-gradient(lighten( #181818, 0%),  #181818);
  //background: linear-gradient(45deg, #181818, lighten(#181818, 5%));
  background-color: #181818;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../img/lights.svg);
    background-size: contain;
    background-repeat: repeat no-repeat;
    background-position: top center;
    height: 20%;
    width: 100%;
    z-index: 0;
    animation: glow 3s ease-in-out infinite;
  }

  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url(../img/ta-taa.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 200vmax;
    width: 200vmax;
    z-index: 0;
    transform-origin: center center;
    animation: step-rotate 300s steps(3600) infinite;
  }


  &.confetti-background::before {
    height: 100%;
    background-image: url('https://my.connect.ly/assets/confetti.80cfbc4b.svg');
    background-size: cover;
  }
}

@keyframes glow {
  0%, 100% {
    filter: drop-shadow(0 0 3px rgba($secondary, 0.7));
  }

  50% {
    filter: drop-shadow(0 0 5px rgba($secondary, 1));
  }
}

@keyframes step-rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#paper {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  mix-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  background-image: url(../img/paper-min.png);
  opacity: 0.5;
}

main {
  position: relative;
  z-index: 6;
}

.container {
  min-height: 100vh;

  @supports (-webkit-appearance:none) {
    @at-root .os-android & {
      min-height: calc(100vh - 56px);
    }
  }
}

#banner {
  background-image: url(../img/20212.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  flex-grow: 1;
  margin-top: 20vh;
}

#claim-btn {
  white-space: nowrap;
}

#content {
  max-height: 100%;
  place-content: center;
  padding-top: 9vh;
  padding-bottom: 2vh;

  // background-color: rgba($color: #000000, $alpha: .4);
  // -webkit-backdrop-filter: blur(40px);
  // backdrop-filter: blur(40px);
  & > * {
    // width: 60%;
    margin: auto;
  }
}

#code {
  font-family: monospace;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5em;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.shake {
  animation-name: shake;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 100%;
}


.modal {
  //animation: modalBg .4s forwards ease-out;

  &#modal {
    background-image: url("https://my.connect.ly/assets/confetti.80cfbc4b.svg");
    background-position: center;
    background-size: cover;
  }

  .modal-dialog .modal-content {
    //min-height: 40vh;
    //animation: modalDialog .8s forwards ease-out;
    background-color: rgba($color: #000000, $alpha: 0.4);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    .modal-body {
      align-items: center;
    }
  }
}
.tooltip {
  z-index: 800000;
}
#copied,
#form-error {
  filter: opacity(0);
  transition: filter .5s ease-in-out, transform .5s ease-in-out;

  &.done {
    filter: opacity(1);
  }
}

#copy-the-code {
  line-height: 1.5em;
}

@keyframes modalBg {
  to {
    background-color: rgba(black, 0.6);
  }
}

@keyframes modalDialog {
  from {
    transform: translateY(100%);
    filter: opacity(0);
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
}

.form-group {
  position: relative;
  margin: 1.5em 0;
  padding: .6em 0;
  @include media-breakpoint-down('md') {
    font-size: 1.1em !important;
  }

  .form-control {
    background-color: rgba(black, 0.3) !important;
    color: white !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);


    & + label {
      position: absolute;
      top: 50%;
      // left: 0.25em;
      padding: 0 0.4em;
      margin: 0;
      transform-origin: center right;
      transform: translateY(-50%);
      transition: transform .2s ease-in-out;
      pointer-events: none;

      &#code-input-label {
        width: 100%;
        transform-origin: center;
        padding: 0.15em;
        white-space: nowrap;
      }
    }

    &.filled,
    &:focus,
    &:focus,
    &:checked {
      & + label {
        transform: translateY(-200%) scale(0.8);
      }
    }

  }
}


@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(3deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

#announcment {
  color: $primary;
  font-size: 4em;
  text-shadow: 0px 2px 0px white,
  0px 11px 6px black;
}

#watchout {
  font-size: 1.75em;
  background-color: $primary;
  margin: 1em auto;
  padding: 0.7em;
  width: auto;
  display: inline-block;
  font-weight: bold;
}
